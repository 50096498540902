import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Seo from "components/Seo"

import Guide from '../components/Guide'

import '@fontsource/open-sans/700.css'

const CompetitorPage = ({ data, path }) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  const story = useStoryblokState(data.competitorPage);

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${data.competitorPage.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  const description = get(
    content,
    'description',
    `${story.name} - Click to read on.`
  )

  const banner = (
    <aside id="simple-banner">
      <p className="title">Get QuickMail</p>
      <p className="details">
        Switch from {content.competitor_name} and get 20% off your first 6
        months.
      </p>
      <hr />
      <a
        title="Get Started"
        href="https://next.quickmail.com/signup"
        className="link"
      >
        Get Started
      </a>
    </aside>
  )
  return (
    <>
      <Seo
        title={`${story.name} | QuickMail`}
        description={description}
        url={data.slug}
      />
      {story && (
        <Guide
          guide={story}
          content={content}
          contextualBanner={banner}
          additionalContent={
            null
            // <div>
            //   <h2>Still Not Convinced?</h2>
            //   <Link to="/demo" className="btn btn-primary purple-btn">
            //     Request a demo
            //   </Link>
            // </div>
          }
        />
      )}
    </>
  )
}

export default CompetitorPage

export const CompetitorPageQuery = graphql`query CompetitorPageBySlug($slug: String!) {
  competitorPage: storyblokEntry(slug: {eq: $slug}) {
    name
    content
    slug
  }
}
`
